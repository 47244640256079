body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Noto Kufi Arabic',
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font_K24KurdishBold-Bold {
  font-family: 'K24KurdishBold-Bold';
}

/* Layout */

main {
  flex: 1;
}
footer {
  grid-area: footer;
  background-color: #000000;
  color: #ffffff !important;
}
.footer-space {
  margin-bottom: 20px;
}
.media-footer {
  justify-content: space-between;
  margin-right: 20px;
  color: #ffffff !important;
}

#contact i.fa {
  border: 2px solid #b9b4b4;
  border-radius: 60px;
  color: #000000;
  display: flex;
  height: 40px;
  padding: 15px;
  /* margin: 5px; */
  size: 24px;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.colorInfo {
  width: 40px;
}
.footer-link {
  color: #000000 !important;
  font-family: 'Roboto', sans-serif;
}
.footer-link:hover {
  color: #555151 !important;
}
.row-line {
  position: relative;
  border-top: 0.5px solid #858181;
}
.row-footer {
  width: 100%;
}
.footer-title {
  color: #168F9E;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}
.footer-copy {
  background-color: #333333;
  padding: 20px;
  color: #808080;
}
.scroll-button {
  position: relative;
  text-align: right;
  /* margin: 0 20px 20px; */
  height: 5px;
  font-size: 3rem;
  padding: 20px;

  cursor: pointer;
  color: #168F9E;
}

.box ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* Common */

select {
  padding: 0.2rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1rem;
}

/* Header */
.site-container {
  min-height: 100vh;
}
.Wrapper {
  /*display:inline-block; */
  flex-direction: row;
  font-weight: bold;
}
.Wrapper span {
  opacity: 0;
  animation-name: fadein;
  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.Wrapper span:nth-child(1) {
  animation-delay: 0.2s;
}
.Wrapper span:nth-child(2) {
  animation-delay: 0.3s;
}
.Wrapper span:nth-child(3) {
  animation-delay: 0.4s;
}
.Wrapper span:nth-child(4) {
  animation-delay: 0.5s;
}
.Wrapper span:nth-child(5) {
  animation-delay: 0.6s;
}
.Wrapper span:nth-child(6) {
  animation-delay: 0.7s;
}
@keyframes fadein {
  50% {
    opacity: 1;
    transform: scale(1) translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
    filter: blur(0px);
  }
  60% {
    opacity: 0;
    transform: scale(2) translateX(100px) skewY(10deg) skewX(10deg)
      rotateZ(30deg);
    filter: blur(10px);
  }
  75% {
    opacity: 0;
    transform: scale(2) translateY(-100px) skewY(10deg) skewX(10deg)
      rotateZ(30deg);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
    filter: blur(0px);
  }
}
/* Carousel */
.carousel {
  display: flex;
  overflow: hidden;
  height: 80vh;
  background-color: #f5fafd;
  object-fit: cover;
  width: 100%;
}
.carouselcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 180px;

  color: #000000;
}
.button-banner {
  padding: 8px;
  background-color: #41414d;
  width: 180px;
  font-size: 20px;
  font-weight: 600;
}
.button-banner:hover {
  background-color: #8d5f4a;
  color: #f2f2f2;
}
.title {
  margin-bottom: 20px;
}

.break-div {
  border-bottom: 2px solid #808080;
  margin-top: 32px;
}

.titlebanner {
  font-size: 45px;
  font-weight: 900;
  color: #ffffff;
}
.carouselcaption p {
  color: #ffffff;
}
.button {
  padding: 10px;
  font-style: 20px;
  background-color: transparent;
}
/*Shipping Info*/
.shipping-info {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.brands-info{
  background-color: #f5fafd;
  padding-top: 10px;
}

.shipping-info span {
  font-size: 1em;
  color: #f9c882;
}


img.small {
  max-width: 5rem;
  width: 100%;
}

img.large {
  width: 100%;
  height: 500px;
  object-fit: contain;
  padding: 1rem;
}
.small-img-group {
  width: 100%;
  height: 100%;

  object-fit: cover;
}
.image-group {
  justify-content: center;
  height: 100%;
}
.noborder {
  border: 0;
}

.small-img-col {
  flex-basis: 24%;
  cursor: pointer;
}
.small-img {
  width: 100%;
}
.small-img-category {
  max-width: 7rem;
  text-align: center;
}
.small-img-category-list {
  max-width: 3rem;
  text-align: center;
}

/* Card */
.box {
  height: 100%;
  color: rgb(0, 0, 0);
  background: #ffffff;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border: 0px #c9c7c4 solid;
  margin-top: 0px;
  position: relative;
  border-radius: 15px;
}
.box:hover {
  box-shadow: 0 0 10px rgba(151, 147, 147, 0.1);
  opacity: 0.5;
  border: 3 #c3bfba solid;
  border-radius: 15px;
}
.box-img {
  height: 100%;
  width: 100%;
  justify-content: center;
  color: rgb(0, 0, 0);
  background: #ffffff;
  font-family: 'Ubuntu', sans-serif;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  filter: alpha(opacity=60);
}

.box-img:hover {
  opacity: 0.6;
  filter: alpha(opacity=100);
}
@media only screen and (max-width: 1024px) {
  .box {
    margin: 0 0 20px;
  }
}
.preview-color-link {
  color: #000000;
}
.preview-color-link:hover {
  color: #ffffff;
}
.box .discount-label {
  color: #ffffff;
  width: 50px;
  height: 50px;
  margin: 15px 15px;
  background: #8d5f4a;
  border: 1px #8d5f4a solid;
  border-radius: 50%;
  list-style: none;
  position: absolute;
  text-align: center;
  padding: 11px 0px;
}

.preview-color {
  color: #41414d;
  width: 40px;
  height: 40px;
  margin-bottom: 3px;
  background: #f2eae7;
  border-radius: 50%;
  list-style: none;
  padding: 7px;
  cursor: pointer;
}
.preview-color:hover {
  color: #ffffff;
  background-color: #41414d;
}
#registerTipv {
  border-radius: 0;
  width: auto;
  padding: 8px;
}
.price-color {
  font-weight: 600;
  font-size: 19px;
  color: #8d5f4a;
  /* text-align: center; */
}

.text-color {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
}

.service-name {
  text-align: center;
  padding: 10px;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
}
.service-name:hover {
  color: #4a4745;
}

.feature {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 60px 0 20px 0;
}
.products {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.row-product {
  width: 100%;
}

/* section */
.container-adv {
  background-color: #f7f8fa;
  text-align: center;
  margin-top: 0;
}

.container-section {
  background-color: #f9c882;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px;
}
.container-section-bottom {
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  margin-top: 32px;
}

.container-section-first {
  background-color: #c8c8c8;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.section-info {
  margin-top: 10px;
}
.section-info-bottom {
  margin-top: 60px;
}
.bottom-text {
  font-size: 30px;
  font-weight: 500;
}

.rating span:last-child {
  color: #404040;
}

/* images */
button.thumbnail {
  padding: 0;
  border: 0;
}
.img-large {
  max-width: 100%;
}
/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
/* Form */

/* Checkout Steps */

/*pagination */
.row-number {
  justify-content: center;
  padding-left: 5px;
}

.slider-category {
  display: flex;

  width: 100%;
}
.App-slider {
  /* font-family: sans-serif; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Navbar */
.navbar {
  background: transparent;
  color: #e8e8e8 !important;
  /* border-bottom: 1px solid #ddd8d8; */
}
.navbar.scroll {
  background: #e8e8e8;
  color: rgb(255, 255, 255) !important;
  opacity: 0.97;
}
.navbar-brand {
  font-size: 24px;
  font-weight: 600;
  color: rgb(0, 0, 0) !important;
  padding: 0px 30px 8px;
  text-decoration: none;
}
.title-section {
  font-size: 24px;
  font-weight: 600;
  padding: 0px 30px 8px;
}

.brand-footer {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff !important;
  padding: 0px 0px;
  text-decoration: none;
}

.brand-footer-logo span {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff !important;
  padding: 0px 0px;
  text-decoration: none;
}

.brand-footer-logo p {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff !important;
  padding: 4px 42px;
  text-decoration: none;
  text-wrap: wrap;
}
.navbar-brand span {
  font-size: 32px;
  font-weight: 800;
  color: #ee7204;
  text-decoration: none;
}
.navbar-brand.scroll {
  color: rgb(0, 0, 0) !important ;
}
.nav-button {
  color: rgb(252, 252, 252) !important ;
}
.nav-button-toggle {
  color: rgb(29, 27, 27) !important ;
  background-repeat: no-repeat;
}
.nav-button-toggle-icon {
  background-color: #168F9E !important ;
}

.nav-button.scroll {
  color: rgb(255, 255, 255) !important ;
}
.navbar-nav {
  padding: 10px;
  width: 100%;
}
.nav-link {
  color: rgb(25, 24, 24) !important  ;
  margin-left: 8px;
  font-size: 18px;
  font-weight: 600;
}

.nav-link.scroll {
  color: rgb(25, 24, 24) !important  ;
}
.nav-link:hover {
  color: #a2a3aa !important;
}
.header {
  position: absolute;

  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.imgHeader {
  position: relative;
  width: 100%;
  height: 100%;
}

.our-products {
  background-color: #c8c8c8;
}
.imgHeaderService {
  position: relative;

  width: 100%;
  height: 100%;
}
.bodycontact {
  background: #eff1f3 !important;
}
.formcontact {
  background: #eff1f3 !important;
}
.input_text {
  background: #ffffff !important;
  border: 0;
}
.textcolor {
  color: #fff;
}

.z-glossy {
  z-index: 1000;
  font-size: 5vw;
  margin-top: 11vh;
 
}

.header-job {
  font-size: 2vw;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000;
  opacity: 0.6;
}
.overlayService {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000;
  opacity: 0.5;
}

.content-other {
  position: absolute;
  top: 25%;
  left: 30%;
  color: #fff;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

.content {
  position: absolute;
  top: 25%;
  left: 10%;
  color: #fff;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

.projectCard {
  margin: 10px 10px;
}
.content h6 {
  font-size: 60px;
  font-weight: 700;
}

.content span {
  font-size: 1em;
  /* color: #f69821; */
  color: #f9c882;
}
.h1-title {
  color: #000000;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
}
/* Section */
.bg-image {
  /* background-image: url('/public/images/back.png'); */
  margin-top: 7vh;
  /* min-height: 80vh; */
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.aboutdiv {
  min-height: 70vh;
}
.bgabout-image {
  background-image: url('/public/images/backabout.jpg');
  min-height: 30vh;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.contact_form {
  justify-content: center;
  align-items: center;
}
.bgcontact-image {
  background-image: url('/public/images/contactback.jpg');
  min-height: 30vh;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
}

.transparent {
  height: 100%;
  width: 100%;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.8);
}
.text-justify {
  font-size: 18px;
  text-align: justify;
  padding: 12px;
}

.img-section {
  position: relative;
  width: 100%;
}
.img-section-bottom {
  justify-content: center;
  width: 100%;
  height: 50vh;
  margin: 0;
  border-radius: 40px 0;
}
.img-icon {
  position: flex;
  width: 170px;
  margin: 0 0px 0 0px;
}
.chartBar {
  display: flex;
  justify-content: space-around;
}
.textChart {
  text-align: center;
  font-size: 18px;
  font-weight: 800;
}

.makeitCenter {
  text-align: center;
}
.agency-About {
  color: black;
  background-color: white;
  margin-top: 50px;
  border-radius: 40px 0;
  margin-bottom: 50px;
  padding: 20px;
}

.languageIcons {
  color: #fff;
}
.text-link {
  text-decoration: none;
  color: #f69821;
}
.text-link:hover {
  color: #f9c882;
}

.send-btn {
  text-decoration: none;
  background-color: #827d7f;
  color: #fff;
}
.send-btn:hover {
  background-color: #168F9E;
}
.img-contact {
  border-radius: 40px 0;
}

.reveal {
  position: relative;
  transform: translateY(80px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
